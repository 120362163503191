import md5 from "md5";
import Thumbor from "./thumbor-js-url-builder";
import apiKeys from "@/config/apiKeys";
import { ossURL } from "./request";
import { OSS_TYPE } from "@/config/defaultSettings";

/**
 * 深拷贝
 * @param {*} source
 * @returns
 */
export function deepClone(source) {
  if (!source) return;
  let target;
  if (typeof source === "object") {
    // 根据source类型判断是新建一个数组还是对象
    target = Array.isArray(source) ? [] : {};
    // 遍历source，并且判断是source的属性才拷贝
    for (const key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        if (typeof source[key] !== "object") {
          target[key] = source[key];
        } else {
          // 如果内部属性存在复杂数据类型，使用递归实现深拷贝
          target[key] = deepClone(source[key]);
        }
      }
    }
  } else {
    target = source;
  }
  return target;
}

/**
 * 签名算法 - java接口
 * @param {*} obj
 * @param {*} secretKey
 * @returns
 */
export function getSign(obj, secretKey) {
  if (Object.prototype.toString.call(obj) === "[object Object]") {
    let str = "";
    const sortArr = Object.keys(obj).sort();
    sortArr.forEach((key) => {
      if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
        let value = obj[key];
        if (typeof value === "object") {
          if (Object.prototype.toString.call(value) === "[object Object]") {
            value = filterNullValue(value);
          }
          value = JSON.stringify(value);
        }
        str += key + "=" + value + "&";
      }
    });
    str = str + "secretKey=" + secretKey;
    return md5(str).toUpperCase();
  }
  return "";
}

/**
 * 去除{}中key值为空的项
 * @param {*} obj
 * @returns
 */
function filterNullValue(obj) {
  const newObj = {};
  const arr = Object.keys(obj);
  arr.forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      // console.log(key, obj[key])
      const value = obj[key];
      if (Object.prototype.toString.call(value) === "[object Object]") {
        newObj[key] = filterNullValue(value);
      } else {
        newObj[key] = value;
      }
    }
  });
  return newObj;
}

/**
 * 生成32位随机数的方法
 * @returns
 */
export function createNonce() {
  const arr = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  let str = "";
  const L = arr.length - 1;
  for (let i = 0; i < 32; i++) {
    str += arr[getRandomIntInclusive(0, L)];
  }
  return str;
}

/**
 * 得到一个两数之间的随机整数，包括两个数在内
 * @param {*} min
 * @param {*} max
 * @returns
 */
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值
}

/**
 * minIO 图片上传: 后端返回地址 => 前端展示地址
 * @param {*} url
 * @param {*} config
 * @returns
 */
export function buildThumborUrl(url, config) {
  // 是否返回安全地址
  const SECURITY = true;
  // 初始化Thumbor实例
  const thumbor = new Thumbor(apiKeys.imageSecurityKey, ossURL);

  if (!url || typeof url !== "string") {
    console.warn("The url is required and it should be a string");
    return;
  }

  config = config || {};

  if (config.width && isNaN(config.width)) {
    console.warn("The width should be a number");
    return;
  }
  if (config.height && isNaN(config.height)) {
    console.warn("The height should be a number");
    return;
  }

  const width = config.width === undefined ? 800 : config.width || 0;
  const height = config.height || 0;

  if (SECURITY) {
    return thumbor.setImagePath(url).resize(width, height).buildUrl();
  }
  url = url.charAt(0) === "/" ? url.substring(1) : url;
  return `${ossURL}/unsafe/${width}x${height}/${url}`;
}

/**
 * 生成java接口所属的header参数
 * @param {*} params
 * @returns
 */
export function createHeaders() {
  // 上传header参数预处理
  const timestamp = Date.now();
  const nonce = createNonce();
  const signObj = {
    "X-validate-accessKey": apiKeys.accessKey,
    "X-validate-timestamp": timestamp,
    "X-validate-nonceStr": nonce,
  };
  const sign = getSign(signObj, apiKeys.secretKey);

  return {
    "X-validate-accessKey": apiKeys.accessKey,
    "X-validate-timestamp": timestamp,
    "X-validate-nonceStr": nonce,
    "X-validate-sign": sign,
  };
}

/**
 * 根据不同环境得到不同的值
 */
export function getEnvWords(key) {
  return process.env.VUE_APP_API_ENV === "prod"
    ? key
    : process.env.VUE_APP_API_ENV + "-" + key;
}

/**
 * 获取文件地址
 */
let ossClient = null;
export function getObjectUrl(fileMarks, config) {
  if (!ossClient) ossClient = createOosClient();
  if (fileMarks && typeof fileMarks === "string") {
    let fileMark = fileMarks.split(",")[0];
    if (OSS_TYPE === 2) {
      let ext = fileMark.split(".")[1];
      ext = ext ? "." + ext : "";
      config = config || {};
      if (!config.origin) {
        const arr = [];
        if (config.width && typeof config.width === "number") {
          arr.push(config.width + "w");
        }
        if (config.height && typeof config.height === "number") {
          arr.push(config.height + "h");
        }
        if (arr.length) {
          arr.push("1l");
          fileMark = fileMark + "@oosImage|" + arr.join("_") + ext;
          // console.log("fileMark", fileMark);
        } else {
          fileMark = fileMark + "@oosImage|800w_1l" + ext;
        }
      }
      const bucketName = fileMark.split("/")[0];
      const KeyName = fileMark.replace(bucketName + "/", "");
      const Expires = 86400;
      return ossClient.getSignedUrl("getObject", {
        Bucket: getEnvWords(bucketName),
        Key: KeyName,
        Expires,
      });
    } else {
      return buildThumborUrl(fileMark, config);
    }
  }
  return "";
}

export function createOosClient() {
  const s3Config = {
    accessKeyId: apiKeys.ctyunOssAk,
    secretAccessKey: apiKeys.ctyunOssSk,
    endpoint: "oos-hazz.ctyunapi.cn",
    signatureVersion: "v4", // 可选v2 或v4
    apiVersion: "2006-03-01",
    s3ForcePathStyle: true,
  };
  return new window.OOS.S3(s3Config);
}
