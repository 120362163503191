<template>
  <div class="company-wrapper">
    <div class="company-stat">
      <div class="mod-title">
        <i class="ic"></i>
        <span class="text">企业类型统计</span>
      </div>
      <div class="stat-box">
        <div
          v-if="companyTypeStat.length"
          class="box"
          :class="'length' + companyTypeStat.length"
        >
          <div class="item" v-for="item in companyTypeStat" :key="item.id">
            <div class="con">
              <!-- <dv-digital-flop class="num" :config="item.numberConf" /> -->
              <strong class="number">{{ item.fCounter }}</strong>
              <span class="unit">家</span>
            </div>
            <div class="lab inaline">{{ item.companyType }}</div>
          </div>
        </div>
        <div class="box" v-else>
          <div class="empty">
            <div class="icon-empty"></div>
            <div class="empty-text">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
    <div class="company-info-box">
      <div class="mod-title" @click="toExternalLink">
        <i class="ic"></i>
        <span class="text">企业信息</span>
      </div>
      <div class="company-list-wrapper" @click="toExternalLink">
        <dv-scroll-board
          :config="companyList"
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import star from "../assets/imgs/ic_star_yellow.png";

function formatter(number) {
  const numbers = number.toString().split("").reverse();
  const segs = [];

  while (numbers.length) segs.push(numbers.splice(0, 3).join(""));

  return segs.join(",").split("").reverse().join("");
}
export default {
  data() {
    return {
      companyTypeList: [],
      records: [],
    };
  },
  mounted() {
    this.getData();

    setInterval(() => {
      this.getData();
    }, 300000);
  },
  computed: {
    companyTypeStat: function () {
      let size = this.companyTypeList.length <= 3 ? 40 : 28;
      return this.companyTypeList.map((item) => {
        return {
          ...item,
          fCounter: formatter(item.count),
          numberConf: {
            number: [item.count],
            content: "{nt}",
            textAlign: "center",
            style: {
              fontSize: size,
              fill: "#fff",
              fontWeight: "500",
            },
            formatter,
          },
        };
      });
    },
    companyList: function () {
      return {
        data: this.records,
        align: ["center"],
        rowNum: 6,
        waitTime: 1000,
        oddRowBGC: "rgba(46, 96, 153, 0)",
        evenRowBGC: "rgba(3, 169, 244, 0)",
        // carousel: 'page'
      };
    },
  },
  methods: {
    toExternalLink() {
      window.location.href = 'https://datav.aliyun.com/share/8662dcbc1691d7c4bb21226178e82617'
    },
    getIndustryTypeStats() {
      request({
        url: "/datacenter/dataV/industryTypeStatistics",
        method: "get",
      })
        .then((res) => {
          if (res.code === 200) {
            let arr = res.data || [];
            this.companyTypeList = arr.map((item, index) => {
              return {
                ...item,
                id: (index + 1 + Math.random()).toFixed(6),
              };
            });
          } else {
            this.$msg(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          this.$msg("网络异常");
        });
    },
    getCompanyList() {
      request({
        url: "/datacenter/companyInfo/page",
        method: "get",
        params: { current: 1, size: 1000, isDisplay: 0 },
      })
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            let records = res.data.records.map((record) => {
              return [
                `<div style="display: flex; align-items: center; position: relative; margin: 0 -10px">
                  <img style="width: 16px; height: 16px;" src="${star}" />
                  <span style="margin-left: 16px; font-size: 14px; color: #fff">${record.companyName}</span>
                  <div style="position: absolute;left: 0; bottom: 0; width: 100%; height: 1px; background: #4D9FF9"><div>
                </div>`,
              ];
            });
            if (res.data.total % 2 !== 0) {
              records = records.concat(records);
            }
            this.records = [...records];
          } else {
            this.$msg(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          this.$msg("网络异常");
        });
    },
    getData() {
      this.getIndustryTypeStats();
      this.getCompanyList();
    },
  },
};
</script>

<style lang="scss" scoped>
.company-wrapper {
  height: 100%;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  .company-stat {
    flex-shrink: 0;
    .mod-title {
      display: flex;
      align-items: center;
      height: 24px;
      .ic {
        width: 24px;
        height: 24px;
        background: url(../assets/imgs/ic_building.png) no-repeat center
          center/100% 100%;
      }
      .text {
        margin-left: 8px;
        font-size: 18px;
        color: #ffffff;
      }
    }
    .stat-box {
      margin-top: 20px;
      height: 220px;
      background: linear-gradient(
        180deg,
        rgba(6, 22, 94, 0.7) 0%,
        rgba(0, 72, 203, 0.7) 100%
      );
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(
          90deg,
          rgba(76, 140, 216, 0) 0%,
          #55a8ff 50%,
          rgba(81, 152, 234, 0) 100%
        );
      }
      &::after {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(
          90deg,
          rgba(76, 140, 216, 0) 0%,
          #55a8ff 50%,
          rgba(81, 152, 234, 0) 100%
        );
      }
      .box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        &.length1,
        &.length2 {
          .item {
            width: 50%;
            &:last-child::after {
              width: 0;
            }
          }
        }
        &.length4,
        &.length5,
        &.length6 {
          padding: 10px 0;
          justify-content: flex-start;
          flex-wrap: wrap;
          .item {
            height: 50%;
            &::after {
              top: 24px;
              height: 46px;
            }
          }
          .con {
            .number {
              font-size: 30px;
            }
            .unit {
              font-size: 12px;
            }
          }
          .lab {
            margin-top: 12px;
            font-size: 12px;
          }
        }
      }
      .empty {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .icon-empty {
          width: 118px;
          height: 78px;
          background: url(../assets/imgs/ic_empty.png) no-repeat;
          background-size: cover;
          background-position: center center;
        }
        .empty-text {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 500;
          color: #5c90ff;
          line-height: 20px;
        }
      }
      .item {
        height: 100%;
        width: 33.3%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &::after {
          position: absolute;
          top: 45px;
          right: 0;
          content: " ";
          width: 1px;
          height: 132px;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0),
            rgba(113, 188, 253, 1),
            rgba(255, 255, 255, 0)
          );
        }
        &:nth-child(3)::after,
        &:nth-child(6)::after {
          width: 0;
        }
        .con {
          text-align: center;
          .number {
            font-size: 36px;
            color: #fff;
            font-weight: 500;
          }
          .unit {
            margin-left: 4px;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .lab {
          width: 90%;
          margin-top: 32px;
          font-size: 14px;
          font-weight: 500;
          color: #83bfff;
          text-align: center;
        }
      }
    }
  }
}
.company-info-box {
  margin-top: 28px;
  height: 100%;
  padding: 40px 40px 30px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    rgba(6, 22, 94, 0.7) 0%,
    rgba(0, 72, 203, 0.7) 100%
  );
  position: relative;
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      90deg,
      rgba(76, 140, 216, 0) 0%,
      #55a8ff 50%,
      rgba(81, 152, 234, 0) 100%
    );
  }
  &::after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      90deg,
      rgba(76, 140, 216, 0) 0%,
      #55a8ff 50%,
      rgba(81, 152, 234, 0) 100%
    );
  }
  .mod-title {
    display: flex;
    align-items: center;
    height: 24px;
    cursor: default;
    .ic {
      width: 24px;
      height: 24px;
      background: url(../assets/imgs/ic_qi.png) no-repeat center center/100%
        100%;
    }
    .text {
      margin-left: 6px;

      font-size: 20px;
      color: #ffffff;
    }
  }
  .company-list-wrapper {
    padding: 15px 0 0 0;
    height: 100%;
    cursor: default;
  }
}
</style>
