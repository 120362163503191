<template>
  <div class="area-stat-wrapper">
    <div class="module-box area-stat-mod">
      <div class="mod-title">
        <i class="ic"></i>
        <span class="text">区域统计</span>
      </div>
      <div class="company-list-wrapper">
        <dv-scroll-board
          :config="conf.areaList"
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
    <div class="module-box finance-trend-mod">
      <div class="mod-title">
        <i class="ic"></i>
        <span class="text">产值纳税趋势图</span>
      </div>
      <div class="company-list-wrapper">
        <v-chart class="chart" :option="option" autoresize />
      </div>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import {
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";
import request from "@/utils/request";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

const colors = ["#ED39A4", "#0F95FF"];

export default defineComponent({
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "dark",
  },
  data() {
    return {
      regionList: [],
    };
  },
  mounted() {
    this.getAreaStat();
    setInterval(() => {
      this.getAreaStat();
    }, 300000);
  },
  computed: {
    conf: function () {
      return {
        areaList: {
          header: ["区域", "就业人口", "企业数量", "面积"],
          data: this.regionList,
          // [
          //   ["庞口镇", 125, 4, 21312],
          //   ["邢家南镇", 144, 5, 26640],
          //   ["小王果庄镇", 186, 6, 31968],
          //   ["西演镇", 353, 11, 58608],
          //   ["蒲口乡", 0, 0, 0],
          //   ["锦华街道", 545, 15, 79920],
          //   ["晋庄乡", 139, 4, 23443],
          //   ["庞家佐镇", 0, 0, 0],
          // ],
          align: ["center", "center", "center", "center"],
          columnWidth: [134, 100, 100, 80],
          rowNum: 5,
          waitTime: 1000,
          headerBGC: "rgba(41,173,248, 0)",
          oddRowBGC: "rgba(41,173,248, 0.2)",
          evenRowBGC: "rgba(41,173,248, 0)",
          // carousel: 'page'
        },
      };
    },
  },
  methods: {
    getAreaStat() {
      request({
        url: "/datacenter/dataV/regionStatistics",
        method: "get",
      })
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
           // ...
           let records = res.data || []
           records = records.map(item => {
             return [
              item.regionName, 
              item.totalEmployeeNumber || 0,
              item.companyCount || 0,
              item.totalArea || 0,
            ]
           })
           if (res.data.total % 2 !== 0) {
              records = records.concat(records);
            }
           this.regionList = records

          } else {
            this.$msg(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          this.$msg("网络异常");
        });
    },
  },
  setup() {
    const option = ref({
      backgroundColor: "rgba(3, 21, 84, 0)",
      // title: {
      //   text: 'Stacked Line'
      // },
      textStyle: {
        color: "#fff",
      },
      // tooltip: {
      //   trigger: "axis",
      //   valueFormatter: (value) => value + "%",
      // },
      legend: {
        // show: false,
        // orient: 'vertical',
        // bottom: 'bottom',
        left: 40,
        top: 0,
        data: ["产值", "纳税"],
        textStyle: {
          color: "#fff",
        },
      },
      grid: {
        top: "14%",
        left: "10%",
        right: "5%",
        bottom: "10%",
        // containLabel: true
      },
      // grid: {
      //   left: '3%',
      //   right: '4%',
      //   bottom: '3%',
      //   containLabel: true
      // },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {}
      //   }
      // },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["", "一季度", "二季度", "三季度", "四季度", ""],
        // name: '季度'
        axisLine: {
          lineStyle: {
            color: "#29A8F1",
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "rgba(119, 203, 251, 0.3)",
            // type: "dotted",
          },
        },
      },
      yAxis: {
        type: "value",
        // name: "（%）
        axisLine: {
          show: true,
          lineStyle: {
            color: "#29A8F1",
          },
        },
        splitLine: {
          lineStyle: {
            color: "rgba(119, 203, 251, 0.3)",
            // type: "dotted",
          },
        },
      },

      series: [
        {
          name: "产值",
          type: "line",
          data: [0, 0, 0, 0, 0, 0],
          // symbol: "circle", // circle 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
          // symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[0],
            width: 2,
          },
          itemStyle: {
            opacity: 0,
          },
          // areaStyle: {
          //   color: areaColors[0],
          // },
        },
        {
          name: "纳税",
          type: "line",
          data: [0, 0, 0, 0, 0, 0],
          // symbol: "rect",
          // symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[1],
            width: 2,
          },
          itemStyle: {
            opacity: 0,
          },
          // areaStyle: {
          //   color: areaColors[0],
          //   shadowColor: "#000",
          // },
        },
      ],
    });
    return { option };
  },
});
</script>

<style lang="scss" scoped>
.area-stat-wrapper {
  height: 100%;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
}

.module-box {
  height: 100%;
  padding: 24px 24px 20px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    rgba(6, 22, 94, 0.7) 0%,
    rgba(0, 72, 203, 0.7) 100%
  );
  position: relative;
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      90deg,
      rgba(76, 140, 216, 0) 0%,
      #55a8ff 50%,
      rgba(81, 152, 234, 0) 100%
    );
  }
  &::after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      90deg,
      rgba(76, 140, 216, 0) 0%,
      #55a8ff 50%,
      rgba(81, 152, 234, 0) 100%
    );
  }
  &.area-stat-mod {
    height: 354px;
    flex-shrink: 0;
  }
  &.finance-trend-mod {
    margin-top: 24px;
  }
  .mod-title {
    display: flex;
    align-items: center;
    height: 24px;
    .ic {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: linear-gradient(
        90deg,
        rgba(25, 119, 255, 1),
        rgba(0, 194, 255, 1)
      );
    }
    .text {
      margin-left: 8px;
      font-size: 18px;
      color: #ffffff;
    }
  }
  .company-list-wrapper {
    padding: 15px 0 0 0;
    height: 100%;
  }
}
</style>
