<template>
  <div id="data-view">
    <dv-loading v-if="loading">加载中...</dv-loading>
    <dv-custom-full-screen-container v-else class="container">
      <top-header />
      <div class="main-content">
        <div class="left-wrap">
          <CompanyData />
        </div>
        <div class="middle-wrap">
          <GaoyangMap2 />
        </div>
        <div class="right-wrap">
          <AreaStatData />
        </div>
      </div>
      <!-- <button class="type-btn" @click="type =  type === 1 ? 2 : 1">{{type === 1 ? '卫星地图' : '普通地图' }}</button> -->
      <!-- <bottom-footer /> -->
    </dv-custom-full-screen-container>
  </div>
</template>

<script>
import TopHeader from "./TopHeader";
// import BottomFooter from './BottomFooter'
import GaoyangMap2 from "./GaoyangMap2.vue";
import CompanyData from "./CompanyData.vue";
import AreaStatData from "./AreaStatData.vue";

export default {
  name: "DataView",
  components: {
    TopHeader,
    // BottomFooter,
    GaoyangMap2,
    CompanyData,
    AreaStatData,
  },
  data() {
    return {
      loading: false,
      timer: null,
      type: 1, // 1 - 普通 2 - 卫星
    };
  },
  created() {
    // 测试
    // setTimeout(() => {
    //   console.log('msg')
    //   this.$msg('Hello')
    // }, 2000)
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    getMockData() {},
  },
};
</script>

<style lang="scss" scoped>
#data-view {
  width: 100%;
  height: 100%;
}
#dv-full-screen-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform-origin: left top;
  z-index: 999;
}
.container {
  background-color: #000066;
  background-image: url("../assets/imgs/bg.jpg");
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // filter: brightness(150%);
}
.main-content {
  // filter: none;
  display: flex;
  height: calc(100% - 184px - 106px); // calc(100% - 112px);
  padding: 0;

  // div {
  //   box-shadow: 0 0 1px 1px #666;
  // }
  .left-wrap {
    height: 100%;
    width: 24%;
    padding-left: 60px;
  }

  .middle-wrap {
    height: 100%;
    width: 51%;
    display: flex;
    flex-direction: column;
    padding: 0 60px;

    .middle-top-wrap {
      height: 25%;
      width: 100%;
      display: flex;
    }
    .middle-top-one {
      height: 100%;
      width: 60%;
    }
    .middle-top-two {
      height: 100%;
      width: 40%;
    }
    .middle-bottom {
      height: 75%;
      width: 100%;
    }
  }

  .right-wrap {
    height: 100%;
    width: 25%;
    padding-right: 60px;
    display: flex;
    flex-direction: column;

    .right-one {
      height: 25%;
      width: 100%;
    }
    .right-two {
      padding-top: 10px;
      height: 75%;
      width: 100%;
    }
  }
}
.type-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
</style>
