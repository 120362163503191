<template>
  <div id="top-header">
    <div class="center-title" @click="toExternalLink">
      <img
        class="title-img"
        src="../assets/imgs/text_title.png"
        alt="高阳地方产业数字地图"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
  methods: {
    toExternalLink() {
      window.location.href = 'https://datav.aliyun.com/share/5e1528b31f3378bc2a49622c56fae366'
    }
  }
};
</script>

<style lang="scss">
#top-header {
  position: relative;
  width: 100%;
  height: 184px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  background: url(../assets/imgs/bg_title.png) no-repeat;
  background-position: center top;
  background-size: 100% auto;
  z-index: 2;
  .center-title {
    position: absolute;
    left: 50%;
    top: 24px;
    width: 450px;
    transform: translateX(-50%);
    .title-img {
      width: 100%;
    }
  }
}
</style>
