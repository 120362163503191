<template>
  <div class="gy-map-wrap">
    <div class="label-line">
      <div class="item" v-for="item in tagList" :key="item.id">
        <img class="icon-img" :src="item.url" />
        <span class="text">{{ item.name }}</span>
      </div>
    </div>
    <div class="select-company-type">
      <div class="select-item">
        <span class="text">{{ currentCompanyType || '全部企业' }}</span>
        <i class="down"></i>
      </div>
      <div class="ant-dropdown">
        <ul class="ant-dropdown-menu">
          <li class="ant-dropdown-menu-item" v-for="item in companyTypes" :key="item.id" @click="selectCompanyTypeHandle(item)">
            <span class="ant-dropdown-menu-title-content">{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div id="gyMapContainer" class="gy-map-container"></div>
    <!-- <div class="switch-btns">
      <button @click="switchStyle('normal')">normal</button>
      <button @click="switchStyle('dark')">dark</button>
      <button @click="switchStyle('light')">light</button>
      <button @click="switchStyle('whitesmoke')">whitesmoke</button>
      <button @click="switchStyle('fresh')">fresh</button>
      <button @click="switchStyle('grey')">grey</button>
      <button @click="switchStyle('graffiti')">graffiti</button>
      <button @click="switchStyle('macaron')">macaron</button>
      <button @click="switchStyle('blue')">blue</button>
      <button @click="switchStyle('wine')">wine</button>
    </div> -->
  </div>
</template>

<script>
import request from "@/utils/request";
import requestPlatform from "@/utils/request-platform";
import AMapLoader from "@amap/amap-jsapi-loader";
import { getObjectUrl } from "@/utils/tool";
window._AMapSecurityConfig = {
  securityJsCode: "830a4427e2872c781bf8f24a38fb60d9",
};
export default {
  data() {
    return {
      AMap: null,
      markers: [],
      tagList: [],
      zoom: 11.36,
      companyTypes: [
        {
          id: 0,
          name: '全部企业',
          value: ''
        },
      ],
      currentCompanyType: ''
    };
  },
  created() {},
  mounted() {
    this.initMap();
    window.hevueImgPreview = this.hevueImgPreview;
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "048993e23968e7737e87da9390830418", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "Map3D",
          "AMap.DistrictLayer",
          "AMap.Scale",
          "AMap.ToolBar",
          "AMap.DistrictSearch",
          "AMap.TileLayer",
          "AMap.Object3D",
          "AMap.Object3DLayer",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("gyMapContainer", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 11.36, //初始地图级别
            center: [115.816, 38.65], //初始地图中心点
            // pitch: 35,
            rotation: 0,
            mapStyle: "amap://styles/grey",
            // features: ['road']
            // mask: [[115.816000,38.62000],[115.926000,38.73000],[115.706000,38.50000]],
          });

          // 创建面覆盖物
          // const polygonPath = [
          //   new AMap.LngLat(116.46, 39.93),
          //   new AMap.LngLat(116.44, 39.91),
          //   new AMap.LngLat(116.49, 39.91)
          // ]

          // const polygon = new AMap.Polygon({
          //   path: polygonPath
          // })

          // this.map.add(polygon)

          // 创建省份图层

          // 颜色辅助方法
          // var colors = {};
          // var getColorByAdcode = function (adcode) {
          //     if (!colors[adcode]) {
          //         var gb = Math.random() * 155 + 50;
          //         colors[adcode] = 'rgb(' + gb + ',' + gb + ',255)';
          //     }

          //     return colors[adcode];
          // };

          var disProvince;

          disProvince && disProvince.setMap(null);
          var adcode = 130628;
          disProvince = new AMap.DistrictLayer.Province({
            zIndex: 12,
            adcode: [adcode],
            depth: 2,
            styles: {
              fill: "rgba(57, 175, 251, 0.25)",
              "province-stroke": "cornflowerblue",
              "city-stroke": "white", // 中国地级市边界
              "county-stroke": "#71D4F9", // 中国区县边界
              "county-stroke-weight": 3,
            },
          });

          disProvince.setMap(this.map);
          
          // 根据缩放展示label
          this.map.on("zoomend", () => {
            console.log("zoomend", this.map.getZoom());
            this.zoom = this.map.getZoom()
            this.markers.forEach((marker) => {
              marker.setLabel(
                this.zoom < 13
                  ? undefined
                  : {
                      // offset: new AMap.Pixel(20, 20),  //设置文本标注偏移量
                      content: `<div class='info'>${marker.getTitle()}</div>`, //设置文本标注内容
                      direction: "bottom", //设置文本标注方位
                    }
              );
            });
          });

          this.getData();

          setInterval(() => {
            this.getData();
          }, 300000);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    createMarker(record) {
      const Amap = this.AMap;
      let marker = new Amap.Marker({
        position: [record.longitude, record.latitude],
        title: record.markTitle,
        icon: record.icon, //: '//vdata.amap.com/icons/b18/1/2.png',
        zooms: record.zooms,
        zIndex: record.zIndex,
        map: this.map,
        label:
        this.zoom < 13 // record.zIndex > 12 // || this.zoom < 13
            ? undefined
            : {
                // offset: new AMap.Pixel(20, 20),  //设置文本标注偏移量
                content: `<div class='info'>${record.markTitle}</div>`, //设置文本标注内容
                direction: "bottom", //设置文本标注方位
              },
        // content:'<div class="marker-route marker-marker-bus-from">11132</div>'
      });
      // console.log(marker)
      var _this = this;
      var info = `
        <div class='content-window-card'>
          <div class="info-type">${record.typeName}</div>
          <div class="info-p">名称：${record.fullTitle}</div>
          <div class="info-p">地址：${record.address}</div>
          <div class="info-p">简介：${record.intro || "-"}</div>
          <div class="info-p">${record.imageHtml}</div>
        </div>
      `;
      var infoWindow = new Amap.InfoWindow({
        // isCustom: true,
        offset: new Amap.Pixel(0, -30),
        content: info,
      });
      marker.on("click", markerClick);
      // marker.emit('click', {target: marker});

      function markerClick(e) {
        // infoWindow.setContent(e.target.content);
        infoWindow.open(_this.map, e.target.getPosition());
      }
      this.markers.push(marker);
    },
    getTagList(record) {
      if (record.tagInfoList && Array.isArray(record.tagInfoList)) {
        record.tagInfoList.forEach((item) => {
          if (
            item.type === "company_icon" &&
            this.tagList.findIndex((ele) => ele.id === item.id) === -1
          ) {
            let itemObj = {
              ...item,
              url: getObjectUrl(item.path, { width: 120, height: 0 }),
            };
            this.tagList.push(itemObj);
          }
        });
      }
    },
    createMarkerParams(record, type) {
      // console.log('record', record)
      this.getTagList(record);
      let markTitle = "";
      let typeName = "";
      let zooms = [2, 20];
      let imageHtml = "";
      let fullTitle = "";
      if (type === "park") {
        markTitle =
          record.isAbbreviation === 1 ? record.abbreviation : record.parkName;
        fullTitle = record.parkName;
        zooms = [record.mapSequence, 20];
        typeName = "园区信息";
      } else if (type === "company") {
        markTitle =
          record.isAbbreviation === 1
            ? record.abbreviation
            : record.companyName;
        fullTitle = record.companyName;
        zooms = [record.sequence, 20];
        typeName = "企业信息";
      }

      let address =
        record.provinceString +
        record.cityString +
        record.districtString +
        record.addressString;

      if (record.imageList && record.imageList.length) {
        let urls = record.imageList.map((url) =>
          getObjectUrl(url.path, { width: 1000 })
        );

        imageHtml = record.imageList.map((image, index) => {
          let url = getObjectUrl(image.path, { width: 120, height: 0 });
          return `<image style="margin-right: 2px; width: 60px; height: 60px; object-fit: cover;" src="${url}" onclick="hevueImgPreview(${JSON.stringify(
            urls
          ).replace(/"/g, "&quot;")}, ${index})" />`;
        });
      }
      let params = {
        ...record,
        markTitle,
        fullTitle,
        typeName,
        zooms,
        address,
        imageHtml,
        zIndex: 12,
        icon: undefined,
      };

      if (record.tagInfoList && record.tagInfoList.length) {
        if (
          record.tagInfoList.length === 1 &&
          record.tagInfoList.findIndex(
            (item) => item.type && item.type.indexOf("tag") >= 0
          ) >= 0
        ) {
          this.createMarker(params);
        }
        record.tagInfoList.forEach((tag) => {
          if (tag.path) {
            params.icon = getObjectUrl(tag.path, { width: 0, height: 42 });
          }
          if (tag.type && tag.type.indexOf("tag") >= 0) {
            params.zIndex = 13;
          } else {
            params.zIndex = 12;
          }
          this.createMarker(params);
        });
      } else {
        this.createMarker(params);
      }
    },
    switchStyle(style) {
      this.map.setMapStyle("amap://styles/" + style);
    },
    hevueImgPreview(urls, index) {
      if (urls.length === 1) {
        this.$hevueImgPreview({
          url: urls[0],
          clickMaskCLose: true,
        });
      } else {
        this.$hevueImgPreview({
          multiple: true, // 开启多图预览模式
          nowImgIndex: index, // 多图预览，默认展示第二张图片
          imgList: urls, // 需要预览的多图数组
          clickMaskCLose: true,
        });
      }
    },
    selectCompanyTypeHandle(item) {
      console.log(item)
      if (this.currentCompanyType !== item.value) {
        this.currentCompanyType = item.value
        this.getData()
      }
    },
    getData() {
      Promise.all([
        request({
          url: "/datacenter/companyInfo/page",
          method: "get",
          params: { current: 1, size: 99999, isDisplay: 0, companyType: this.currentCompanyType },
        }),
        request({
          url: "/datacenter/parkInfo/page",
          method: "get",
          params: { current: 1, size: 99999, isDisplay: 0 },
        }),
        requestPlatform({
          url: '/system/sysDictData/list',
          method: "get",
          params: { dictType: 'COMPANY_TYPE', status: 1}
        })
      ])
        .then((arr) => {
          this.map.remove(this.markers);
          const [resCompany, resPark, resCompanyType] = arr;
          console.log(resCompany, resPark, resCompanyType);
          if (resCompany.code === 200) {
            resCompany.data.records.forEach((record) => {
              this.createMarkerParams(record, "company");
            });
            console.log(this.tagList);
          } else {
            this.$msg(resCompany.msg);
          }

          if (resPark.code === 200) {
            resPark.data.records.forEach((record) => {
              this.createMarkerParams(record, "park");
            });
          } else {
            this.$msg(resPark.msg);
          }

          if (resCompanyType.code === 200) {
            const companyTypes = [
                {
                id: 0,
                name: '全部企业',
                value: ''
              },
            ]
            this.companyTypes = companyTypes.concat(resCompanyType.data.map(item => ({id: item.id, name: item.dictValue, value: item.dictValue })))
          } else {
            this.$msg(resCompanyType.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          this.$msg("网络异常");
        });

      // .then(res => {
      //   console.log(res)
      //   if (res.code === 200) {
      //     res.data.records.forEach(record => {
      //       this.createMarkerParams(record, 'park')
      //     })
      //   } else {
      //     this.$msg(res.msg)
      //   }
      // }).catch(err => {
      //   console.error(err)
      //   this.$msg('网络异常')
      // })

      // request({
      //   url: '/datacenter/parkInfo/page',
      //   method: 'get',
      //   params: {current: 1, size: 1000, isDisplay: 0}
      // }).then(res => {
      //   console.log(res)
      //   if (res.code === 200) {
      //     res.data.records.forEach(record => {
      //       this.createMarkerParams(record, 'park')
      //     })
      //   } else {
      //     this.$msg(res.msg)
      //   }
      // }).catch(err => {
      //   console.error(err)
      //   this.$msg('网络异常')
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
.gy-map-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .label-line {
    display: flex;
    .item {
      margin-right: 36px;
      display: inline-flex;
      .icon-img {
        height: 24px;
      }
      .text {
        margin-left: 8px;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }

  .select-company-type {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    &:hover {
      .ant-dropdown {
        display: block;
      }
    }
    .select-item {
      cursor: pointer;
      height: 20px;
      display: flex;
      align-items: center;
      font-size: 14px;
      .down {
        margin-left: 8px;
        border: 6px solid transparent;
        border-bottom: none;
        border-top: 6px solid #fff;
        border-radius: 2px;

      }
    }
    .ant-dropdown {
      position: absolute;
      right: 0;
      top: 28px;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.88);
      font-size: 14px;
      line-height: 1.6;
      list-style: none;
      z-index: 1050;
      display: none;
      border-radius: 2px;
      background-color: rgba(255,255,255, 1);
      box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-dropdown-menu {
      padding: 4px;
      list-style-type: none;
      background-clip: padding-box;
      border-radius: 2px;
      outline: none;
      
      max-height: 60vh;
      overflow-x: hidden;
      overflow-y: auto;
      /* 自定义滚动条整体样式 */
      &::-webkit-scrollbar {
        width: 6px;
      }
      /* 自定义滚动条滑块样式 */
      &::-webkit-scrollbar-thumb {
        background-color: #ccc; /* 设置滑块的颜色 */
        border-radius: 3px; /* 设置滑块的圆角 */
      }
      
      /* 自定义滚动条轨道样式 */
      &::-webkit-scrollbar-track {
        background-color: #fff; /* 设置轨道的颜色 */
      }
    }
    .ant-dropdown-menu-item {
      margin: 0;
      padding: 5px 12px;
      color: rgba(0, 0, 0, 0.88);
      font-weight: normal;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1)
      }
    }
    .ant-dropdown-menu-title-content {
      white-space:nowrap;
    }
  }

  .gy-map-container {
    position: absolute;
    left: 0;
    top: 45px;
    width: 100%;
    height: calc(100% - 45px);
  }
  .switch-btns {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
  }
}
</style>
